<app-header *ngIf="env.header" class="sticky top-0 w-full z-[2]"></app-header>

<ng-container *ngIf="userCashier != 100 ">
    <app-sidebar *ngIf="env.sidebar" [menu]="menu"></app-sidebar>
</ng-container>



<main id="cttMain">
    <router-outlet class="contents"></router-outlet>
    <app-footer *ngIf="env.footer"></app-footer>
</main>