import { getEnvironment } from './../../../../environment/environment';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import Swal from 'sweetalert2';
import { NotificationService } from '../../../../services/notification.service';
import { Observable, Subscription } from 'rxjs';
import { Data, XtrasService } from '../../../../services/xtras.service';
import { AuthService, UserType } from '../../../../services/auth.service';
import { UserModel } from '../../../../services/user-model';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonCapYeiComponent } from '../../../../components/button/button.component';
import { DropdownMenuCapYeiComponent } from '../../../../components/dropdown-menu/drowdown-menu.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SettingsProvider } from '../../../../services/settings';
import { HttpClientProvider } from '../../../../services/http-client';
import { LoadingService } from '../../../../services/loading.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
	imports: [
    CommonModule, 
    RouterModule, 
    MatMenuModule,
    ButtonCapYeiComponent,
    DropdownMenuCapYeiComponent,
    LazyLoadImageModule,
  ]
})
export class HeaderComponent implements OnInit {

  private unsubscribe: Subscription[] = [];

  env = getEnvironment();

  back_location = null;

  user$: Observable<UserType>;
  data$: Observable<Data[]>;
  
  notifications = [];
  unread_notifications_count = 0;

   userCashier:any = 0;

  constructor(
    public xServices: XtrasService,
    public authService: AuthService,
    public userModel: UserModel,
    private router: Router,
    private http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
  ) {
    this.data$ = this.xServices.sharingObservable;
    this.user$ = this.authService.user$;

    this.user$.subscribe((data) => {
      //console.log(data, "desde header")
      //this.userCashier = data?.role_id
      this.userCashier = data;
      this.userCashier = this.userCashier['role_name'];
    })
  }

  ngOnInit() {
    
  }

  logout(){
    Swal.fire({
      title: 'Cerrar Sesión',
      text: '¿Desea cerrar su Sesión?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result: any) => {
      if(result.value) {
        this.doLogout();
      } else if(result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  doLogout() {
    this.authService.logout();
    this.toast.success('Sesión cerrada', 'Cerraste tu sesión exitosamente', 4000);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  closeMenu() {
    // Todo: Añadir servicio en el backend para realizar esta acción
    // const subscr = this.http.getRequest(SettingsProvider.getUrl(`mark-notifications-as-read`)).subscribe((result: any) => {
    //   if(result['status']) {
    //     this.load.dismiss();
    //     this.authService.getUserByToken().subscribe();
    //   } else {
    //     this.load.dismiss();
    //     this.toast.error('Error al leer las notificaciones', result['message'], 4000);
    //   }
    // }, () => {
    //   this.load.dismiss();
    //   this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    //   // this.errorPage();
    // });
    // this.unsubscribe.push(subscr);
  }

  toggleSidebarDesk() {
    $('body').toggleClass('hide__menu');
  }

  toLogin() {
    this.router.navigateByUrl('/auth/login');
  }

  toRegister() {
    this.router.navigateByUrl('/auth/register');
  }

  changeProfile(id: any) {
    this.load.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl(`change-current-membership/${id}`)).subscribe({
      next:(result:any) => {
        console.log(result);
        if(result['status']) {
          this.load.dismiss();
          this.authService.getUserByToken().subscribe();
        } else {
          this.load.dismiss();
          this.xServices.getErrorApi(result, true, '/');
        }
      },
      error: (error: any) => {
        this.load.dismiss();
        this.toast.error('¡Error!', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      }
    });
  }
}
