import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { finalize } from 'rxjs';
import { TokenInterceptorService } from './../../../../src/app/services/interceptor.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './../../../../src/app/services/auth.service';
import { DatePipe } from '@angular/common';
import { HttpClientProvider } from './../../../../src/app/services/http-client';
import { UserModel } from './../../../../src/app/services/user-model';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from './../../../../src/app/components/custom-toast/custom-toast.component';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

declare global {
  var projectEnvironment: typeof environment;
}
window['projectEnvironment'] = environment;

function appInitializer(userService: AuthService) {
  return () => {
    return new Promise<void>((resolve) => {
      userService.getUserByToken().pipe(
        finalize(() => resolve())
      ).subscribe();
    });
  };
}

export function playerFactory() {
  return player
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);


export const appConfig: ApplicationConfig = {
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    DatePipe,
    importProvidersFrom(HttpClientProvider),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(AuthService),
    importProvidersFrom(UserModel),
    importProvidersFrom(LottieModule.forRoot({ player: playerFactory })),
    importProvidersFrom(ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      newestOnTop: false,
      preventDuplicates: true
    })),


    // * Firebase - Start
    // ...firebaseProviders,
    importProvidersFrom(
      AngularFireModule.initializeApp(environment.firebase)
    ),
    importProvidersFrom(
      provideFirebaseApp(() => initializeApp(environment.firebase))
    ),
    importProvidersFrom(provideAuth(() => getAuth())), 
    // importProvidersFrom(provideAnalytics(() => getAnalytics())),
    // ScreenTrackingService,
    // UserTrackingService,
    // * Firebase - End
    provideRouter(
      routes,
      inMemoryScrollingFeature,
    ),
    provideAnimations(), 
  ]
};
