import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService, UserType } from '../../services/auth.service';
import { UserModel } from '../../services/user-model';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { Menu } from '../../interfaces/interfaces';
import { getEnvironment } from '../../environment/environment';

declare var $: any;

@Component({
  selector: 'main-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
  ],
})
export class MainLayoutComponent implements OnInit {

  @ViewChild('cttMain') cttMain?: ElementRef;

  user$: Observable<UserType>;
  title_page: any = '';

  menu: Menu[] = [];

  env = getEnvironment();

  userCashier:any = 0;

  constructor(
    private authService: AuthService,
    public userModel: UserModel
  ) {
    this.menu = [];
    this.user$ = this.authService.user$;
    this.user$.subscribe((data) => {
      //console.log(data, "******");
      //console.log(data?.role_id)
      this.userCashier = data?.role_id

      if (data) {
        (data.permissions ?? []).forEach((permission: string) => {
          switch (permission) {
            case 'page-users':
              this.menu.push({ name: 'Usuarios', url: '/users', external: false, icon: 'fa-light fa-users', iconType: 'icon' })
              break;
            case 'page-users-register':
              this.menu.push({ name: 'Registrar usuario', url: '/users-register', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' },)
              break;
            // case 'page-subscription-abonos':
            //   this.menu.push({ name: 'Abono', url: '/subscription-abonos', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
            //   )

            //   break;
            case 'page-subscription-socios':
              this.menu.push({ name: 'Socios', url: '/subscription-socios/2', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })


              break;
            case 'page-commission-abonos':
              this.menu.push({ name: 'Abonos', url: '/subscription-abonos/1', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-commission-socios':
              this.menu.push({ name: 'Comisiones Sociedades', url: '/commission-socios/2', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )

              break;

            case 'page-seat-map':
              this.menu.push({ name: 'Mapa de asientos', url: '/seat-map', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )

              break;
            case 'page-minute-x-minute':
              this.menu.push({ name: 'Minuto a minuto', url: '/minute-x-minute', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )

              break;
            case 'page-push-notification':
              this.menu.push({ name: 'Notificaciones push', url: '/push-notification', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )

              break;

            case 'page-bulk-load':
              this.menu.push({ name: 'Carga masiva de usuarios', url: '/bulk-load', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )
              break;
            case 'page-assistances':
              this.menu.push({ name: 'Asistencia', url: '/assistances', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-emails':
              this.menu.push({ name: 'Correos', url: '/emails', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-send-email':
              this.menu.push({ name: 'Enviar correo masivo', url: '/send-email', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;

            case 'page-user-suscription':
              this.menu.push({ name: 'Registrar usuario/acreditación', url: '/user-suscription', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-corporative-bulk-load':
              this.menu.push({ name: 'Carga masiva', url: '/corporative-bulk-load', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-corporative-subscriptions-list':
              this.menu.push({ name: 'Acreditaciones', url: '/corporative-subscriptions-list', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-groups':
              this.menu.push({ name: 'Instituciones', url: '/groups', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-cashier':
              this.menu.push({ name: 'Caja', url: '/cashier', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-sales-report':
              this.menu.push({ name: 'Reporte de ventas', url: '/sales-report', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' });

              break;
            case 'page-credentials-report':
              this.menu.push({ name: 'Reporte de credenciales', url: '/credentials-report', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' });

              break;
            case 'page-income-report':
              this.menu.push({ name: 'Reporte de Asistencia', url: '/income-report', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' });

              break;
            case 'page-sale-detail':
              this.menu.push({ name: 'Detalle de ventas', url: '/sale-detail', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-detailt-attandace':
              this.menu.push({ name: 'Detalle de asistencia', url: '/detailt-attandace', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;

            default:
              break;
          }
        })


      }
    })

    // this.user$.subscribe(data => {
    //   if (data) {
    //     this.menu = [
    //       { name: 'Registrar usuario', url: '/register', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' },
    //       { name: 'Abono', url: '/suscriptions', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Socio', url: '/memberships', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Comisiones Abonos', url: '/comission_suscription/1', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Comisiones Sociedades', url: '/comission_society/2', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Mapa de asientos', url: '/seats', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Generador de notificaciones', url: '/push-notifications', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       // { name: 'Reportes', url: '/report', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       // { name: 'Tienda', external: false, submenu: [
    //       // 	{ iconType: 'img', icon: 'assets/img/header/shop.png', url: '/shop', external: false, name: 'Tienda' },
    //       // 	{ iconType: 'img', icon: 'assets/img/header/card.png', url: '/acquire/2', external: false, name: 'Socios' },
    //       // 	{ iconType: 'img', icon: 'assets/img/header/card.png', url: '/acquire/1', external: false, name: 'Abonos' },
    //       // ]},
    //       // { name: 'Mis pagos',external: false, submenu: [
    //       // 	{ iconType: 'img', icon: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Pagos pendientes' },
    //       // 	{ iconType: 'img', icon: 'assets/img/header/book.png', url: '/payments', external: false, name: 'Historial y facturas' },
    //       // ]},
    //       // { name: 'Beneficios', url: '/benefits', external: false },

    //       // { name: 'Solicitud Abono Adicional', url: '/request-subscription/abono-adicional', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       // { name: 'Solicitud Abono Estudiante', url: '/request-subscription/abono-estudiante', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //     ];
    //     if (data.role_id == 1 || data.role_id == 2 || data.role_id == 7) {
    //       this.menu.unshift(
    //         { name: 'Usuarios', url: '/users', external: false, icon: 'fa-light fa-users', iconType: 'icon' }
    //       );
    //     }
    //     if (data.role_id == 7) {
    //       [
    //         { name: 'Registrar usuario', url: '/register', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' },
    //         { name: 'Abono', url: '/suscriptions', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //         { name: 'Socio', url: '/memberships', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //         { name: 'Mapa de asientos', url: '/seats', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //         // { name: 'Solicitud Abono Estudiante', url: '/request-subscription/abono-estudiante', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       ];
    //     }
    //   }
    // });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $(function () {
      $('.toogle_dropdown').on('click', function (this: any) {
        $(this).next('.dropdown').slideToggle();
      });

      $('.toogle_dropdown_a').on('click', function (this: any) {
        $('.dropdown').slideUp()
      });

      $(document).on('click', function (e: any) {
        var target = e.target;
        if (!$(target).is('.toogle_dropdown') && !$(target).parents().is('.toogle_dropdown')) {
          $('.dropdown').slideUp()
        }
      });

      $('.content_header-space').css('padding-top', `${$('.header__menu').outerHeight()}px`);

      $('.btn_a-sidebar').on('click', function () {
        $('.bg__sidebar_left').fadeOut();
        $('.sidebar_left').removeClass('active');
      });
    });

    $('.btn__sidebar, .bg__sidebar_left').on('click', function () {
      $('.bg__sidebar_left').fadeToggle();
      $('.sidebar_left').toggleClass('active');
    });
  }

}
